import React from 'react';
import {BrowserRouter, Routes, Route} from  'react-router-dom';
import $ from 'jquery'

import Home from './pages/home';
import AboutUs from './pages/aboutus'
import Careers from './pages/careers';
import OurWork from './pages/ourwork';
import Directors from './pages/directors';
import Details from './pages/details';
import Project from './pages/project';
import Challenge from './pages/challenge';
import ContactUs from './pages/contactus';
import Error from './pages/error';

import './App.css';

function App() {

// para desplazar el fondo dependiendo la resolucion del dispositivo.

  $(window).scroll(function(){
    var barra = $(window).scrollTop();
    var posicion =  (barra * 0.05);
    var x = window.matchMedia('(max-width: 768px)');
    
    if (x.matches) { // If media query matches

      $('body').css({
        'background-position': 'left -' + posicion + 'px'
      });

    } else {

      $('body').css({
        'background-position': 'center -' + posicion + 'px'
      });

    }

  });

  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path='/careers' element={<Careers />}></Route>
          <Route path='/our-work' element={<OurWork />}></Route>
          <Route path='/directors' element={<Directors />}></Route>
          <Route path='/details/:id' element={<Details />}></Route>
          <Route path='/project/:id' element={<Project />}></Route>
          <Route path='/project/interview' element={<Challenge />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>
          <Route path="*" element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
