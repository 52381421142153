import React from 'react'

import Navbar from '../components/Navbar'
import Details from '../components/Details'
import Footer from '../components/Footer'

import '../App.css'

const Home = () => {

  return (
    <>
      <Navbar />
      <Details />
      <Footer />
    </>
  )
}

export default Home
