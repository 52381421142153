import React, { useRef, useEffect, useState } from 'react';
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';
import { PiSpeakerSimpleHighBold, PiSpeakerSimpleSlashBold, PiPlayCircleBold, PiPauseCircleBold, PiArrowsOutBold } from "react-icons/pi";
import { Link } from 'react-router-dom';

import "./home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgBlobs01 from "../../images/home/1.webp";
import ImgBlobs02 from "../../images/home/2.webp";
import ImgBlobs03 from "../../images/home/3.webp";
import ImgBlobs04 from "../../images/home/4.webp";
import ImgBackground01 from "../../images/home/background-hello.webp";
import ImgBackground02 from "../../images/home/background-studies.webp";
import ImgBackground03 from "../../images/home/background-client.webp";
import ImgLogo01 from "../../images/home/nathan.webp";
import ImgLogo02 from "../../images/home/tigrelab.webp";
import ImgLogo03 from "../../images/home/fox.webp";
import ImgLogo04 from "../../images/home/punkrobot.webp";

import ImgGetInTouch from "../../images/home/umbras.webp";
import VideoDesktop from "../../images/home/video_desktop.webm";
import VideoMovil from "../../images/home/video_movil.webm";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}

export default function Home() {

  let slider;
  const videoRef = useRef(null);
  const [works, setWorks] = useState([]);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    fetch('/home.json')
      .then(response => response.json())
      .then(data => setWorks(data))
      .catch(error => console.error('Error fetching vacancies:', error));
  }, []);

  useEffect(() => {
    videoRef.current.muted = isMuted;
  }, [isMuted]);

  const handleToggleMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleTogglePlay = () => {
    videoRef.current.play();
  };

  const handleTogglePause = () => {
    videoRef.current.pause();
  };

  const handleToggleScreen = () => {
    videoRef.current.requestFullscreen();
  };

  const handleMouseOver = (direction) => {
    const interval = setInterval(() => {
      if (direction === 'left') {
        slider.slickPrev();
      } else if (direction === 'right') {
        slider.slickNext();
      }
    }, 400); // Intervalo de tiempo entre cada desplazamiento
  
    // Cuando el ratón sale del elemento, limpiamos el intervalo para detener el desplazamiento continuo
    const onMouseOut = () => {
      clearInterval(interval);
      // Removemos el event listener para evitar que el intervalo se dispare más veces innecesariamente
      document.removeEventListener('mouseout', onMouseOut);
    };
  
    // Agregamos un event listener para detectar cuando el ratón sale del elemento
    document.addEventListener('mouseout', onMouseOut);
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    initialSlide: 1,
    speed: 1500,
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    swipeToSlide: true
  };

  

return (
  <>
  <Desktop>
    <div className='homeContainer'>
      <img className='home_blobs_01' src={ImgBlobs01} alt="blob" />
      <img className='home_blobs_02' src={ImgBlobs02} alt="blob" />
      <img className='home_blobs_03' src={ImgBlobs03} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
      <img className='home_blobs_04' src={ImgBlobs04} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
      <div className='videoContainer'>
        <video ref={videoRef} autoPlay playsInline muted loop style={{ width: '100%', objectFit: 'cover' }} controlsList="nodownload">
          <source src={VideoDesktop} type="video/webm" />
        </video>
        <div className='playIcon' onClick={handleTogglePlay}><PiPlayCircleBold /></div>
        <div className='pauseIcon' onClick={handleTogglePause}><PiPauseCircleBold /></div>
        <div className='screenIcon' onClick={handleToggleScreen}><PiArrowsOutBold /></div>
        <div className='speakerIcon' onClick={handleToggleMute}>{isMuted ? <PiSpeakerSimpleSlashBold /> : <PiSpeakerSimpleHighBold />}</div>
      </div>
      <div className='helloContainer'>
        <img className='home_background_01' src={ImgBackground01} alt="background" />
        <div className='titleWelcomeMessage'>Hello!</div>
        <div className='textWelcomeMessage'>Firelight is a studio specializing in 3D animation, developing full productions and original Intellectual Properties with a strong emphasis on storytelling. The company collaborates with brands worldwide to produce ad campaigns, audiovisuals, CG assets to be used in production, and excels in creating stunning cinematics oriented to the videogame industry. Additionally, Firelight offers outsourcing services for all stages of production. The studio has adopted Unreal Engine and other technologies in the production pipeline. Creating emotions and stunning content, we will light up your spark.</div>
      </div>
      <div className='studiesContainer'>
        <img className='home_background_02' src={ImgBackground02} alt="background" />
        <div className='titleCaseStudies'>CASE STUDIES</div>
        <div className="slider" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
          <div className='navContainer'>
            <div className="navSlider" onMouseOver={() => handleMouseOver('left')}></div>
            <div className="navSliderCenter"></div>
            <div className="navSlider" onMouseOver={() => handleMouseOver('right')}></div>
          </div>
          <Slider ref={sl => (slider = sl)} {...settings}>
            {works.map((work, index) => (
                <div key={index}>
                    <Link to={`${work.url}`}>
                      <img className="cardImageHome" src={require(`../../images/ourwork/${work.image}`)} alt="work"/>
                    </Link>                    
                    <div className="cardTitleHome">{work.title}</div>
                </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className='clientContainer'>
        <img className='home_background_03' src={ImgBackground03} alt="background" />
        <div className='titleClients'>CLIENTS</div>
        <div className='logosContainer'>
          <a href='https://nathanlove.com/' rel='noopener noreferrer' target='_blank' className='logoClients'>
            <img className='logoClientsNormal' src={ImgLogo01} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
          </a>
          <a href='https://tigrelab.com/' rel='noopener noreferrer' target='_blank' className='logoClients'>
            <img className='logoClientsBig' src={ImgLogo02} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
          </a>
          <a href='https://punkrobot.cl/' rel='noopener noreferrer' target='_blank' className='logoClients'>
            <img className='logoClientsSmall' src={ImgLogo04} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
          </a>
        </div>
        <div className='titlePartners'>PARTNERS</div>
          <div className='logosContainer'>
            <a href='https://www.foxrenderfarm.com/' rel='noopener noreferrer' target='_blank' className='logoClients'>
              <img className='logoClientsXL' src={ImgLogo03} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
            </a>
          </div>
        <a href="/contact-us" className='GetInTouchContainer'>
          <img className='getInTouch' src={ImgGetInTouch} alt='getInTouch'/>
        </a>
      </div>
    </div>
  </Desktop>
  <Mobile>
    <div className='homeContainer'>
      <img className='home_blobs_01' src={ImgBlobs01} alt="blob" />
      <img className='home_blobs_02' src={ImgBlobs02} alt="blob" />
      <img className='home_blobs_03' src={ImgBlobs03} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
      <img className='home_blobs_04' src={ImgBlobs04} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
      <div className='videoContainer'>
        <video ref={videoRef} autoPlay playsInline muted loop style={{ width: '100%', objectFit: 'cover' }} controlsList="nodownload">
          <source src={VideoMovil} type="video/webm" />
        </video>
        <div className='playIcon' onClick={handleTogglePlay}><PiPlayCircleBold /></div>
        <div className='pauseIcon' onClick={handleTogglePause}><PiPauseCircleBold /></div>
        <div className='speakerIcon' onClick={handleToggleMute}>{isMuted ? <PiSpeakerSimpleSlashBold /> : <PiSpeakerSimpleHighBold />}</div>
      </div>
      <div className='helloContainer'>
        <img className='home_background_01' src={ImgBackground01} alt="background" />
        <div className='titleWelcomeMessage'>Hello!</div>
        <div className='textWelcomeMessage'>Firelight is a studio specializing in 3D animation, developing full productions and original Intellectual Properties with a strong emphasis on storytelling. The company collaborates with brands worldwide to produce ad campaigns, audiovisuals, CG assets to be used in production, and excels in creating stunning cinematics oriented to the videogame industry. Additionally, Firelight offers outsourcing services for all stages of production. The studio has adopted Unreal Engine and other technologies in the production pipeline. Creating emotions and stunning content, we will light up your spark.</div>
      </div>
      <div className='studiesContainer'>
        <img className='home_background_02' src={ImgBackground02} alt="background" />
        <div className='titleCaseStudies'>CASE STUDIES</div>
        <div className="slider" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
          <Slider {...settingsMobile}>
            {works.map((work, index) => (
              <div key={index}>
                  <Link to={`${work.url}`}>
                    <img className="cardImageHome" src={require(`../../images/ourwork/${work.image}`)} alt="work"/>
                  </Link>                    
                  <div className="cardTitleHome">{work.title}</div>
              </div>
            ))}    
          </Slider>
        </div>
      </div>
      <div className='clientContainer'>
        <img className='home_background_03' src={ImgBackground03} alt="background" />
        <div className='titleClients'>CLIENTS</div>
        <div className='logosContainer'>
          <a href='https://nathanlove.com/' rel='noopener noreferrer' target='_blank' className='logoClients'>
            <img className='logoClientsNormal' src={ImgLogo01} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
          </a>
          <a href='https://tigrelab.com/' rel='noopener noreferrer' target='_blank' className='logoClients'>
            <img className='logoClientsBig' src={ImgLogo02} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
          </a>
          <a href='https://punkrobot.cl/' rel='noopener noreferrer' target='_blank' className='logoClients'>
            <img className='logoClientsSmall' src={ImgLogo04} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
          </a>
        </div>
        <div className='titlePartners'>PARTNERS</div>
          <div className='logosContainer'>
            <a href='https://www.foxrenderfarm.com/' rel='noopener noreferrer' target='_blank' className='logoClients'>
              <img className='logoClientsXL' src={ImgLogo03} alt="client" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true"/>
            </a>
          </div>
        <a href="/contact-us" className='GetInTouchContainer'>
          <img className='getInTouch' src={ImgGetInTouch} alt='getInTouch'/>
        </a>
      </div>

    </div>
  </Mobile>
  </>
)}