import React, { useEffect, useState, useRef } from 'react'

import { PiSpeakerSimpleHighBold, PiSpeakerSimpleSlashBold, PiPlayCircleBold, PiPauseCircleBold, PiArrowsOutBold } from "react-icons/pi";

import { FaImdb, FaInstagramSquare, FaFacebookSquare, FaLinkedin, FaVimeo } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import "./details.css";
import ImgLeftBlobs from "../../images/project/left.webp";
import ImgRightBlobs from "../../images/project/right.webp";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}

export default function Project() {

  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const { id } = useParams();
  const [director, setDirector] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleToggleMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleTogglePlay = () => {
    videoRef.current.play();
  };

  const handleTogglePause = () => {
    videoRef.current.pause();
  };

  const handleToggleScreen = () => {
    videoRef.current.requestFullscreen();
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchDirectorData = async () => {
      try {
        const response = await fetch('/directors.json');
        const data = await response.json();
  
        const foundDirector = data.find((director) => director.id === id);
  
        if (!foundDirector) {
          console.log('No se encontró el proyecto');
          return;
        }
  
        setDirector(foundDirector);
        
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
  
    fetchDirectorData();
    
  });

  return (
    <>
      {isModalOpen && (
        <div className="modal" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content">
            <img src={selectedImage} alt="selected-img" />
          </div>
        </div>
      )}
      <Desktop>
        <div className='detailsContainer'>
          <img className='left-blobs' src={ImgLeftBlobs} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
          <img className='right-blobs' src={ImgRightBlobs} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          <img className='profilePic' src={director?.profile} alt='profile' />
          <div className='titleDirector'>{director?.name}</div>
          <div className='desc-director-container'>
            <div className='descriptionDirector'>{director?.description}</div>
            <div className='social-container'>
              <div className='social-buttons'>
                {director?.vimeo && <a href={director.vimeo} rel='noopener noreferrer' target='_blank' ><FaVimeo style={{ fontSize: '2vw', color: 'black' }} /></a>}
                {director?.linkedin && <a href={director.linkedin} rel='noopener noreferrer' target='_blank' ><FaLinkedin style={{ fontSize: '2vw', color: 'black' }} /></a>}
                {director?.imdb && <a href={director.imdb} rel='noopener noreferrer' target='_blank' ><FaImdb style={{ fontSize: '2vw', color: 'black' }} /></a>}
                {director?.instagram && <a href={director.instagram} rel='noopener noreferrer' target='_blank' ><FaInstagramSquare style={{ fontSize: '2vw', color: 'black' }} /></a>}
                {director?.twitter && <a href={director.twitter} rel='noopener noreferrer' target='_blank' ><FaSquareXTwitter style={{ fontSize: '2vw', color: 'black' }} /></a>}
                {director?.facebook && <a href={director.facebook} rel='noopener noreferrer' target='_blank' ><FaFacebookSquare style={{ fontSize: '2vw', color: 'black' }} /></a>}
              </div>
            </div>
          </div>
          <div className='picContainer'>
            <div className='line-01-container'>
              {director && (
                <>
                  {director?.pic01.endsWith('.webm') ? (
                    <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={director.pic01} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-movil'
                      src={director.pic01}
                      alt='sneakpic'
                      onClick={() => handleImageClick(director.pic01)}
                    />
                  )}
                  {director?.pic02.endsWith('.webm') ? (
                    <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={director.pic02} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-desktop'
                      src={director.pic02}
                      alt='sneakpic'
                      onClick={() => handleImageClick(director.pic02)}
                    />
                  )}
                </>
              )}
            </div>
            <div className='line-02-container'>
              {director && (
                  <>
                    {director?.pic03.endsWith('.webm') ? (
                      <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                        <source src={director.pic03} type='video/webm' />
                      </video>
                    ) : (
                      <img
                        className='sneakpic-movil'
                        src={director.pic03}
                        alt='sneakpic-movil'
                        onClick={() => handleImageClick(director.pic03)}
                      />
                    )}
                    {director?.pic04.endsWith('.webm') ? (
                      <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                        <source src={director.pic04} type='video/webm' />
                      </video>
                    ) : (
                      <img
                        className='sneakpic-desktop'
                        src={director.pic04}
                        alt='sneakpic'
                        onClick={() => handleImageClick(director.pic04)}
                      />
                    )}
                  </>
                )}
            </div>
          </div>
          <div className='video-container'>
            {director && (
              <>
                <video ref={videoRef} autoPlay playsInline muted loop style={{ width: '100%', objectFit: 'cover' }} controlsList="nodownload">
                  <source src={director.reel} type='video/mp4' />
                </video>
                <div className='reelPlayIcon' onClick={handleTogglePlay}><PiPlayCircleBold /></div>
                <div className='reelPauseIcon' onClick={handleTogglePause}><PiPauseCircleBold /></div>
                <div className='reelScreenIcon' onClick={handleToggleScreen}><PiArrowsOutBold /></div>
                <div className='reelSpeakerIcon' onClick={handleToggleMute}>{isMuted ? <PiSpeakerSimpleSlashBold /> : <PiSpeakerSimpleHighBold />}</div>
              </>
            )}
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className='detailsContainer'>
          <img className='left-blobs' src={ImgLeftBlobs} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
          <img className='right-blobs' src={ImgRightBlobs} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          <img className='profilePic' src={director?.profile} alt='profile' />
          <div className='titleDirector'>{director?.name}</div>
          <div className='desc-director-container'>
            <div className='descriptionDirector'>{director?.description}</div>
              <div className='social-container'>
                <div className='social-buttons'>
                  {director?.vimeo && <a href={director.vimeo} rel='noopener noreferrer' target='_blank' ><FaVimeo size={30} color="black" /></a>}
                  {director?.linkedin && <a href={director.linkedin} rel='noopener noreferrer' target='_blank' ><FaLinkedin size={30} color="black" /></a>}
                  {director?.imdb && <a href={director.imdb} rel='noopener noreferrer' target='_blank' ><FaImdb size={30} color="black" /></a>}
                  {director?.instagram && <a href={director.instagram} rel='noopener noreferrer' target='_blank' ><FaInstagramSquare size={30} color="black" /></a>}
                  {director?.twitter && <a href={director.twitter} rel='noopener noreferrer' target='_blank' ><FaSquareXTwitter size={30} color="black" /></a>}
                  {director?.facebook && <a href={director.facebook} rel='noopener noreferrer' target='_blank' ><FaFacebookSquare size={30} color="black" /></a>}
                </div>
              </div>
          </div>
          <div className='picContainer'>
            <div className='line-01-container'>
              {director && (
                <>
                  {director?.pic01.endsWith('.webm') ? (
                    <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={director.pic01} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-movil'
                      src={director.pic01}
                      alt='sneakpic'
                      onClick={() => handleImageClick(director.pic01)}
                    />
                  )}
                  {director?.pic02.endsWith('.webm') ? (
                    <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={director.pic02} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-desktop'
                      src={director.pic02}
                      alt='sneakpic'
                      onClick={() => handleImageClick(director.pic02)}
                    />
                  )}
                </>
              )}
            </div>
            <div className='line-02-container'>
              {director && (
                <>
                  {director?.pic03.endsWith('.webm') ? (
                    <video className='sneakpic-movil' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={director.pic03} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-movil'
                      src={director.pic03}
                      alt='sneakpic-movil'
                      onClick={() => handleImageClick(director.pic03)}
                    />
                  )}
                  {director?.pic04.endsWith('.webm') ? (
                    <video className='sneakpic-desktop' autoPlay playsInline muted loop controls controlsList="nodownload">
                      <source src={director.pic04} type='video/webm' />
                    </video>
                  ) : (
                    <img
                      className='sneakpic-desktop'
                      src={director.pic04}
                      alt='sneakpic'
                      onClick={() => handleImageClick(director.pic04)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className='video-container'>
            {director && (
              <>
                <video ref={videoRef} autoPlay playsInline muted loop style={{ width: '100%', objectFit: 'contain' }} controlsList="nodownload">
                  <source src={director.reel} type='video/mp4' />
                </video>
                <div className='reelPlayIcon' onClick={handleTogglePlay}><PiPlayCircleBold /></div>
                <div className='reelPauseIcon' onClick={handleTogglePause}><PiPauseCircleBold /></div>
                <div className='reelSpeakerIcon' onClick={handleToggleMute}>{isMuted ? <PiSpeakerSimpleSlashBold /> : <PiSpeakerSimpleHighBold />}</div>
              </>
            )}
          </div>
        </div>
      </Mobile>
    </>
  )
}